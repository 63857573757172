'use strict';

$(document).ready(function() {
  
  $('.popup-vimeo').magnificPopup({ 
    type: 'iframe'
  });

  $('.open-popup-link').magnificPopup({
    type:'inline',
    midClick: true
  });

});