'use strict';

var bookdemo_submitting = false;
$("#form").submit(function(event) {
  event.preventDefault();

  if (bookdemo_submitting) return;

  var data = {
    name: $("#name").val(),
    company: $("#company").val(),
    email: $("#email").val(),
    phone: $("#phone").val(),
    language: 'sv'
  };

  if (!data.email.match(/^[^@]+@[^.]+(\.[^.]+)+$/) && data.phone.length < 5) {
    alert("Vänligen fyll i e-postadress eller telefonnummer!");
    return;
  }

  bookdemo_submitting = true;

  $.ajax({
    type: "POST",
    url: "/json/bookdemo/",
    data: data,
    dataType: "json"
  }).always(function() {
    bookdemo_submitting = false;
  }).done(function() {
    $('form').fadeOut("slow", function() {
      $('.success').show();
    });
  }).fail(function() {
    alert(
      "Ett tillfälligt fel uppstog i kommunikationen till servern.\n" +
      "Vänligen försök igen eller kontakta oss direkt."
    );
  });
});