// Open login url upon enter click 
$('body').keypress(function (e) {
    var key = e.which,
        loginButton = ('a.button.login'),
        loginUrl = $(loginButton).attr('href');
    
    if(key == 13) {
       if ( (!$.magnificPopup.instance.isOpen) && ($(loginButton).length)) {
            window.location.href = loginUrl;
            return true;  
        }
    }
});

// Append another footer color for Pro page
if( $('#main.page-pro').length ){
  $('.footer').addClass('page-pro');
}




