'use strict';

mainContentMargin();

var toggleMenuButton = document.querySelector('.hamburger'),
    togglePopUpContact = document.querySelector('.open-popup-link'),
    togglePopUpDemo = document.querySelector('.open-popup-link.button'),
    mainContent = ('#main'),
    heroImageAvailable = ('.hero-image'),
    navigation = ('.nav'),
    height = $(window).scrollTop();


// Mobile navigation toggle
if (toggleMenuButton) {

    var showMenu = false;
    var topNav = document.querySelector('.navList');

    toggleMenuButton.addEventListener('click', function(event) {

        event.preventDefault();

        if (!showMenu) {
            $('body').addClass('navOpen');
            $(topNav).addClass('open');
            $('.hamburger').addClass('hamburger-open');
        } else {
            $('body').removeClass('navOpen');
            $(topNav).removeClass('open');
            $('.hamburger').removeClass('hamburger-open');
        }

        showMenu = !showMenu;
    });
}

// Mobile navigation toggle CONTACT
if (togglePopUpContact) {
    togglePopUpContact.addEventListener('click', function(event) {
        event.preventDefault();
        if (showMenu) {
            $('.navOpen').addClass('enableScroll');

            $(".mfp-close" ).click(function() {
              $('.navOpen').removeClass('enableScroll');
            });
        }
    });
}


// Mobile navigation toggle DEMO
if (togglePopUpDemo) {
    togglePopUpDemo.addEventListener('click', function(event) {
        event.preventDefault();
        if (showMenu) {
            $('.navOpen').addClass('enableScroll');

            $(".mfp-close" ).click(function() {
              $('.navOpen').removeClass('enableScroll');
            });
        }
    });
}

$(window).on('load', function(){
    if ($(window).width() < 480) {
        $('body').addClass('mobileview');
    }
});

// Hide transparency on scroll
$(window).on('load scroll', function(){
    transparentScroll();
});


function transparentScroll() {
    var height = $(window).scrollTop();

    if (height > 80) {
        $('.transparency').switchClass('transparent-true', 'transparent-false', 1000, 'easeOutQuart');
    }  else {
        $('.transparency').switchClass('transparent-false', 'transparent-true', 1000, 'easeInQuart');
    }
}

function mainContentMargin() {
    $(mainContent).each(function() {
        if ($(heroImageAvailable).is(':first-child')) {

           $(mainContent).css('margin', '0');
        }
    });
}
