'use strict';

$(window).load(function() {

  $('.flexslider').flexslider({
    animation: "slide", 
    slideshow: true,
    slideshowSpeed: 8000,
    useCSS: true, 
    touch: true,
    pauseOnAction: false,
    pauseOnHover: false,
    directionNav: true,
    controlNav: true
  });


  if ($('html.touch').length ) {
    $('.flex-direction-nav').hide();
    $('.flex-control-paging').show();
  }

});