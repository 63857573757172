languageSelector();

function languageSelector() {
    $('html').click(function() {
        $('.tooltip-menu').hide();
        $('.language-selector').removeClass('open');
    })

    $('.navList').click(function(e){
        e.stopPropagation();
    });

    $('.language-selector').click(function(e) {
        $('.tooltip-menu').toggle();
        $(this).addClass('open');

        // Close language toggle on scroll
        var currentTogglePosition = $(window).scrollTop();
        var closingToggleMaxHeight = (currentTogglePosition + 400);
        var closingToggleMinHeight = (currentTogglePosition - 600);

        $(window).on('scroll', function(){ 
            var currentUserPosition = $(window).scrollTop();
            if ((currentUserPosition > closingToggleMaxHeight) || (currentUserPosition < closingToggleMinHeight)) {
                $('.tooltip-menu').fadeOut(350);
            }
        });
    });
}